import {
  getObjectData,
  setObjectData,
} from "../../../../../../utils/helpers.DynamicForm";

const preventive_hashmap = [
  [
    "oneTimeScreenForAbdominal",
    "oneTimeScreenForAbdominal.isOneTimeScreenForAbdominal",
  ],
  [
    "oneTimeScreenForAbdominaleducationProvided",
    "oneTimeScreenForAbdominal.isOneTimeScreenForAbdominalSubAnswer.isEducationProvided",
  ],
  ["oneTimeScreenForAbdominalComment", "oneTimeScreenForAbdominal.comment"],
  [
    "oneTimeScreenForHepatitis",
    "oneTimeScreenForHepatitis.isoneTimeScreenForHepatitis",
  ],
  [
    "oneTimeScreenForAbdominaleducationProvidedComment",
    "oneTimeScreenForAbdominal.isOneTimeScreenForAbdominalSubAnswer.comment",
  ],
  [
    "oneTimeScreenForHepatitiseducationProvidedComment",
    "oneTimeScreenForHepatitis.isoneTimeScreenForHepatitisSubAnswer.comment",
  ],
  [
    "oneTimeScreenForHepatitiseducationProvided",
    "oneTimeScreenForHepatitis.isoneTimeScreenForHepatitisSubAnswer.isEducationProvided",
  ],
  ["oneTimeScreenForHepatitisComment", "oneTimeScreenForHepatitis.comment"],
  [
    "abdominalRec",
    "recommendations.recommendationsSubAnswers[0].abnominalAneurysmScreening",
  ],
  [
    "hepatitis",
    "recommendations.recommendationsSubAnswers[1].hepatitisCScreening",
  ],

  [
    "abdominalRecComment",
    "recommendations.recommendationsSubAnswers[0].comment",
  ],

  ["hepatitisComment", "recommendations.recommendationsSubAnswers[1].comment"],
  ["preventiveDatatableVal", "preventiveCare.pastThreeYearsSubAnswers"],
  ["inThePastThreeYearsHaveYouHadComment", "preventiveCare.comment"],
  // ["preventive_RecommendationsComment","recommendations.recommendationsSubAnswers[1].comment"],
  // ["preventive_RecommendationsComment","recommendations.recommendationsSubAnswers[0].comment"]
];

export const getPreventiveData = (apiData) => {
  let tempDbData = {};

  preventive_hashmap.forEach((questionPair) => {
    tempDbData[questionPair[0]] = getObjectData(apiData, questionPair[1]);
  });

  // const getOptionsSelectedYesNoNa = (optionValue) => {

  const getOptionsSelectedYesNoNa = (optionValue) => {
    let selectedValue;
    if (optionValue === "1") {
      selectedValue = ["yes"];
    } else if (optionValue === "2") {
      selectedValue = ["no"];
    } else if (optionValue === "3") {
      selectedValue = ["na"];
    } else {
      selectedValue = "";
    }

    return selectedValue;
  };
  const oneTimeScreenForAbdominal = getOptionsSelectedYesNoNa(
    tempDbData["oneTimeScreenForAbdominal"],
  );
  const oneTimeScreenForAbdominalComment =
    tempDbData["oneTimeScreenForAbdominalComment"];

  const oneTimeScreenForHepatitis = getOptionsSelectedYesNoNa(
    tempDbData["oneTimeScreenForHepatitis"],
  );
  const oneTimeScreenForHepatitisComment =
    tempDbData["oneTimeScreenForHepatitisComment"];

  const oneTimeScreenForAbdominaleducationProvided = getOptionsSelectedYesNoNa(
    tempDbData["oneTimeScreenForAbdominaleducationProvided"],
  );
  const oneTimeScreenForHepatitiseducationProvided = getOptionsSelectedYesNoNa(
    tempDbData["oneTimeScreenForHepatitiseducationProvided"],
  );

  const oneTimeScreenForAbdominaleducationProvidedComment =
    tempDbData["oneTimeScreenForAbdominaleducationProvidedComment"];
  const oneTimeScreenForHepatitiseducationProvidedComment =
    tempDbData["oneTimeScreenForHepatitiseducationProvidedComment"];

  const abnominalAneurysmScreeningComment =
    tempDbData["abnominalAneurysmScreeningComment"];
  const hepatitisCScreeningComment = tempDbData["hepatitisCScreeningComment"];

  const abdominalRec = getSelectedCheckboxes(
    [tempDbData["abdominalRec"]],
    ["abnominalAneurysmScreening"],
  );

  const hepatitis = getSelectedCheckboxes(
    [tempDbData["hepatitis"]],

    ["hepatitisCScreening"],
  );

  const abdominalRecComment = tempDbData["abdominalRecComment"];

  const hepatitisComment = tempDbData["hepatitisComment"];

  const preventiveDatatableVal = tempDbData["preventiveDatatableVal"];

  const inThePastThreeYearsHaveYouHadComment =
    tempDbData["inThePastThreeYearsHaveYouHadComment"];

  tempDbData = {
    oneTimeScreenForAbdominal,
    preventiveDatatableVal,
    oneTimeScreenForAbdominalComment,
    oneTimeScreenForHepatitis,
    oneTimeScreenForHepatitisComment,
    oneTimeScreenForAbdominaleducationProvided,
    oneTimeScreenForHepatitiseducationProvided,
    oneTimeScreenForAbdominaleducationProvidedComment,
    oneTimeScreenForHepatitiseducationProvidedComment,
    abnominalAneurysmScreeningComment,
    hepatitisCScreeningComment,
    // abdominal,
    abdominalRec,
    abdominalRecComment,
    hepatitis,
    hepatitisComment,
    inThePastThreeYearsHaveYouHadComment,
  };

  return tempDbData;
};

export const setPreventiveData = (formData) => {
  let tempData = {};

  let payloadData = {};

  const setOptionsSelectedYesNoNa = (optionValue) => {
    let selectedValue;
    if (optionValue?.includes("yes")) {
      selectedValue = "1";
    } else if (optionValue?.includes("no")) {
      selectedValue = "2";
    } else if (optionValue?.includes("na")) {
      selectedValue = "3";
    } else {
      selectedValue = "";
    }

    return selectedValue;
  };
  const oneTimeScreenForAbdominal = setOptionsSelectedYesNoNa(
    formData["oneTimeScreenForAbdominal"],
  );
  const oneTimeScreenForAbdominalComment =
    formData["oneTimeScreenForAbdominalComment"];

  const abdominalSelected = formData["abdominalRec"];

  const abdominalRec = setSelectedCheckboxes(
    abdominalSelected,
    ["abdominalRec"],
    ["abnominalAneurysmScreening"],
  );
  const hepatitisSelected = formData["hepatitis"];

  const hepatitis = setSelectedCheckboxes(
    hepatitisSelected,
    ["hepatitis"],
    ["hepatitisCScreening"],
  );

  const abdominalRecComment = formData["abdominalRecComment"];
  const hepatitisComment = formData["hepatitisComment"];

  const oneTimeScreenForHepatitis = setOptionsSelectedYesNoNa(
    formData["oneTimeScreenForHepatitis"],
  );
  const oneTimeScreenForHepatitisComment =
    formData["oneTimeScreenForHepatitisComment"];

  const oneTimeScreenForAbdominaleducationProvided = setOptionsSelectedYesNoNa(
    formData["oneTimeScreenForAbdominaleducationProvided"],
  );
  const oneTimeScreenForHepatitiseducationProvided = setOptionsSelectedYesNoNa(
    formData["oneTimeScreenForHepatitiseducationProvided"],
  );

  const oneTimeScreenForAbdominaleducationProvidedComment =
    formData["oneTimeScreenForAbdominaleducationProvidedComment"];
  const oneTimeScreenForHepatitiseducationProvidedComment =
    formData["oneTimeScreenForHepatitiseducationProvidedComment"];

  const abdominalAneurysmScreeningComment =
    formData["abdominalAneurysmScreeningComment"];
  const hepatitisCScreeningComment = formData["hepatitisCScreeningComment"];

  const abnominalAneurysmScreeningComment =
    formData["abnominalAneurysmScreeningComment"];

  const preventiveDatatableVal = formData["preventiveDatatableVal"];
  const inThePastThreeYearsHaveYouHadComment =
    formData["inThePastThreeYearsHaveYouHadComment"];
  tempData = {
    ...tempData,
    oneTimeScreenForAbdominal,
    oneTimeScreenForAbdominalComment,
    preventiveDatatableVal,
    oneTimeScreenForHepatitis,
    oneTimeScreenForHepatitisComment,
    oneTimeScreenForAbdominaleducationProvided,
    oneTimeScreenForHepatitiseducationProvided,
    oneTimeScreenForAbdominaleducationProvidedComment,
    oneTimeScreenForHepatitiseducationProvidedComment,
    abnominalAneurysmScreeningComment,
    abdominalAneurysmScreeningComment,
    hepatitisCScreeningComment,
    ...abdominalRec,
    ...hepatitis,
    abdominalRecComment,
    hepatitisComment,
    inThePastThreeYearsHaveYouHadComment,
  };

  preventive_hashmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], tempData[questionPair[0]]);
    //}
  });

  return payloadData;
};

const getSelectedCheckboxes = (optionKeyArr, optionValueArr) => {
  let temp = [];

  optionKeyArr.forEach((ele, index) => {
    if (ele === "1") {
      optionValueArr[index] && temp.push(optionValueArr[index]);
    }
  });

  return temp;
};

const setSelectedCheckboxes = (optionArr, optionKeyArr, optionValueArr) => {
  let temp = {};

  optionValueArr?.forEach((ele, index) => {
    if (optionArr?.includes(ele)) {
      temp[optionKeyArr[index]] = "1";
    }
  });

  return temp;
};
