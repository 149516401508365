import _lodash from "lodash";
import { useUserContext } from "../contexts/UserContext";

export const addDepthToSectionData = (nestedArray, depth = 1) => {
  if (nestedArray.length > 0) {
    nestedArray.forEach((obj) => {
      // obj.name = populateFieldName(obj.label);
      obj.depth = depth;
      if (obj.hasSubQuestions) {
        addDepthToSectionData(obj.subQuestions, depth + 1);
      }
    });
  }
};

export const addMetaData = (nestedArray, depth = 0) => {
  // use this function to add section id, question's id, name(db field) & depth as well as for subquestions
  if (nestedArray.length > 0) {
    let quesId = 1;
    nestedArray.forEach((obj) => {
      // obj.name = populateFieldName(obj.label);
      obj.depth = depth + 1;
      depth > 0 ? (obj.subQuestionId = quesId) : (obj.questionId = quesId);
      quesId += 1;
      if (obj.hasSubQuestions) {
        addMetaData(obj.subQuestions, depth + 1);
      }
    });
  }
};

export const populateFieldName = (label) => {
  const listOfStrings = label.split(" ");
  let genName = "";
  if (listOfStrings.length > 5) {
    genName = generateCamelCase(listOfStrings);
  } else if (listOfStrings.length === 1) {
    genName = label.toLowerCase();
  } else {
    genName = _lodash.camelCase(label);
  }

  let fieldName = genName.replace(/[^a-zA-Z0-9]/g, "");
  return fieldName.substring(0, 30);
};

export const generateCamelCase = (listOfStrings) => {
  let temp = listOfStrings.pop();

  let result = "";

  listOfStrings?.forEach((labelWord) => {
    result += labelWord?.[0] ? labelWord[0].toLowerCase() : "";
  });

  let lastString = temp.charAt(0).toUpperCase() + temp.slice(1);
  result += lastString;

  return result;
};

export const getCurrQuesSelected = (optionObject) => {
  return Object.keys(optionObject).filter(
    (option) => optionObject[option] === "1",
  )[0];
};

export const getCurrQuesSelectedMulti = (optionObject) => {
  return Object.keys(optionObject).filter(
    (option) => optionObject[option] === "1",
  );
};

export const getCurrQuesSubAnswers = (
  optionObject,
  optionName,
  describeLabel = "subAnwers",
) => {
  let temp = {};
  let tempKey = `${optionName}SubAnswers`;
  temp[tempKey] = optionObject[describeLabel];

  return temp;
};

export const getCurrQuesComment = (optionObject, optionName) => {
  let temp = {};
  let tempKey = `${optionName}Comment`;
  temp[tempKey] = optionObject["comment"];

  return temp;
};

export const getCurrQuesDescribe = (
  optionObject,
  optionName,
  describeLabel = "descibe",
) => {
  let temp = {};
  let tempKey = `${optionName}Describe`;
  temp[tempKey] = optionObject[describeLabel];

  return temp;
};

export const getCurrQuesSelectionAndComment = (
  optionObject,
  optionName,
  multiSelect = false,
) => {
  /*
  receives a object and it's name
  returns the selected options inside the object & it's comment
  */

  let selectedValues = [];
  selectedValues[0] = multiSelect
    ? getCurrQuesSelectedMulti(optionObject)
    : getCurrQuesSelected(optionObject);

  let temp = getCurrQuesComment(optionObject, optionName);
  selectedValues[1] = temp;

  return selectedValues;
};

export const getSelectedCheckboxes = (optionKeyArr, optionValueArr) => {
  let temp = [];

  optionKeyArr.forEach((ele, index) => {
    if (ele === "1") {
      optionValueArr[index] && temp.push(optionValueArr[index]);
    }
  });

  return temp;
};

export const setSelectedCheckboxes = (
  optionArr,
  optionKeyArr,
  optionValueArr,
) => {
  let temp = {};

  optionValueArr?.forEach((ele, index) => {
    if (optionArr?.includes(ele)) {
      temp[optionKeyArr[index]] = "1";
    }
  });

  return temp;
};

export const getOptionsSelected = (
  optionObject,
  optionArray,
  treeMapObject,
) => {
  const selectedVariable = optionArray?.filter((option) => {
    return optionObject[option] === "1";
  });

  let selectedOption = selectedVariable?.map((variable) => {
    return treeMapObject[variable];
  });

  return selectedOption;
};

export const setOptionsSelected = (
  optionsSelected,
  optionArray,
  treeMapObject,
) => {
  let temp = {};
  optionArray?.forEach((option) => {
    temp[option] =
      optionsSelected &&
      optionsSelected.length > 0 &&
      optionsSelected.includes(treeMapObject[option])
        ? "1"
        : "";
  });
  return temp;
};

export const getOptionsSelectedYesNoWithThird = (
  optionValue,
  thirdValue = "",
) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else if (optionValue === "3") {
    selectedValue = [thirdValue];
  } else {
    selectedValue = [""];
  }

  return selectedValue;
};

export const setOptionsSelectedYesNoWithThird = (
  optionValue,
  thirdValue = "",
) => {
  let checkValue = optionValue?.[0] || "";
  let selectedValue;
  if (checkValue === "yes") {
    selectedValue = "1";
  } else if (checkValue === "no") {
    selectedValue = "2";
  } else if (thirdValue !== "" && checkValue === thirdValue) {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

export const getOptionsSelectedYesNo = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else {
    selectedValue = [""];
  }

  return selectedValue;
};

export const setOptionsSelectedYesNo = (optionValue) => {
  let checkValue = optionValue?.[0] || "";
  let selectedValue;
  if (checkValue === "yes") {
    selectedValue = "1";
  } else if (checkValue === "no") {
    selectedValue = "2";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

export const getOptionsSelectedYesNoDontKnow = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else if (optionValue === "3") {
    selectedValue = ["dontKnow"];
  } else {
    selectedValue = [""];
  }
  return selectedValue;
};

export const setOptionsSelectedYesNoDontKnow = (optionValue) => {
  let checkValue = optionValue?.[0] || "";
  let selectedValue;
  if (checkValue === "yes") {
    selectedValue = "1";
  } else if (checkValue === "no") {
    selectedValue = "2";
  } else if (checkValue === "dontKnow") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

export const getOptionsSelectedYesNoPartially = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else if (optionValue === "3") {
    selectedValue = ["partially"];
  } else {
    selectedValue = [""];
  }
  return selectedValue;
};

export const setOptionsSelectedYesNoPartially = (optionValue) => {
  let checkValue = optionValue?.[0] || "";
  let selectedValue;
  if (checkValue === "yes") {
    selectedValue = "1";
  } else if (checkValue === "no") {
    selectedValue = "2";
  } else if (checkValue === "partially") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

export const getOptionsSelectedYesNoUnknown = (optionValue) => {
  let selectedValue;
  if (optionValue === "1") {
    selectedValue = ["yes"];
  } else if (optionValue === "2") {
    selectedValue = ["no"];
  } else if (optionValue === "3") {
    selectedValue = ["unknown"];
  } else {
    selectedValue = [""];
  }
  return selectedValue;
};

export const setOptionsSelectedYesNoUnknown = (optionValue) => {
  let checkValue = optionValue?.[0] || "";
  let selectedValue;
  if (checkValue === "yes") {
    selectedValue = "1";
  } else if (checkValue === "no") {
    selectedValue = "2";
  } else if (checkValue === "unknown") {
    selectedValue = "3";
  } else {
    selectedValue = "";
  }

  return selectedValue;
};

export const getSubTreeString = (
  optionName,
  optionValue,
  treeWrapperArray,
  treeMapperObject,
) => {
  let temp;
  const tempIndex = _lodash.findIndex(treeWrapperArray, function (optTree) {
    return optTree?.[0] === optionName;
  });

  const tempTreeNodes = treeWrapperArray?.[tempIndex]
    ? treeWrapperArray[tempIndex][1]
    : [];

  tempTreeNodes.forEach((node, index) => {
    if (treeMapperObject[node] === optionValue) {
      temp = node;
    }
  });
  if (temp && temp !== "") {
    let removeVal =
      temp.substring(temp.length - 3) === "Val" ||
      temp.substring(0, temp.length - 3) === "val"
        ? temp.substring(0, temp.length - 3)
        : temp;
    temp = `${removeVal}Sub`;
  }

  return temp;
};

export const deleteSubTreeData = (formData, subTreeRegex, treeType = 1) => {
  /*
    1 - normal options tree
    2 - yes/no options tree
*/
  let treeKeys = Object.keys(formData);
  let escapeRegex = treeType === 2 ? `${subTreeRegex}Comment` : "";
  treeKeys.forEach((node) => {
    if (node.includes(subTreeRegex) && node !== escapeRegex) {
      delete formData[node];
    }
  });
  return formData;
};

export const getObjectData = (objectData, objectKey, escapeValue = "") => {
  return _lodash.get(objectData, objectKey, escapeValue);
};

export const setObjectData = (objectData, Key, Value = "") => {
  return _lodash.set(objectData, Key, Value);
};

export const flipObjectData = (objectData) => {
  return _lodash.invert(objectData);
};

export const pivotArrayToObjectData = (arrayOfArray) => {
  return _lodash.fromPairs(arrayOfArray);
};

export const getObjectsData = (objectData, objectKey, escapeValue = "") => {
  return _lodash.get(objectData, objectKey, escapeValue);
};
